import {template} from "core-utils";
import {foreignBrandsFilterTemplate} from './foreign-brands-filter-template';
import {svdModel} from "../stockcars/filter/svd-model";
import {STCK_STORE, SELECTORS} from "@oneaudi/stck-store";

export default class ForeignBrandsFilterElement extends HTMLElement {
	static get defaults() {
		return {
			REQUEST_OPTIONS: {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				}
			}
		};
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	connectedCallback() {
		this.initializeComponent();
	}

	async initializeComponent() {
		try {
			const url = await this._getUrl();
			const brandsData = await this._fetchBrands(url);
			if (brandsData.length > 0) {
				this._renderElement(brandsData);
			}
		}
		catch (err) {
			console.warn(err);
		}
	}

	/**
	 * get dealer id from preset Map
	 * @returns {string|null} dealerId or null
	 */
	async _getDealerIdFromPresetMap() {
		let foundDealerID = null;

		await STCK_STORE.waitForStateFromStorePromise({
			conditionFn: (state) => !!SELECTORS.FILTERS.getFilterItemsMap(state).size
		});
		const presetFilterKeys = [...SELECTORS.FILTERS.getPresetFiltersMap(STCK_STORE.state).keys()] || [];
		const presetDealerFilterKeys = presetFilterKeys.filter((key) => (/^dealer.\d+/).test(key));

		if (presetDealerFilterKeys.length) {
			const dealerPreset = SELECTORS.FILTERS.getPresetFiltersMap(STCK_STORE.state).get(presetDealerFilterKeys[0]);
			if (dealerPreset) {
				foundDealerID = dealerPreset.id.replace('dealer.', '');
			}
		}
		return foundDealerID;
	}

	async _getUrl() {
		const svd = await svdModel.getSvdVersion();
		const dealerId = await this._getDealerIdFromPresetMap();
		const version = SETUPS.get('stockcar.scs.version.mapping')['structure/foreign-groups'] || "v1";
		let url = `${SETUPS.get('stockcar.scs.base.url')}${version}/structure/foreign-groups/${SETUPS.get('stockcar.scs.market.path')}?svd=${svd}${(!!dealerId ? "&dealerId=" + dealerId : '')}`;
		return url;
	}

	/**
	 * fetch matching brands from SCS by dealerID
	 * @param {string} url url to fetch data from
	 * @param {object} options_ request options
	 * @returns {Promise} brands array
	 */
	async _fetchBrands(url, options_ = {}) {
		try {
			const requestOptions = {...ForeignBrandsFilterElement.defaults.REQUEST_OPTIONS, ...options_};
			let brandsData = await fetch(url, requestOptions);
			if (brandsData.ok) {
				brandsData = await brandsData.json();
			}
			else {
				throw new Error("could not fetch data from: " + url);
			}
			return brandsData;
		}
		catch (err) {
			console.warn('could not fetch matching brands', err);
			return [];
		}
	}

	/**
	 * renders foreign brands filter element
	 *
	 * @method renderElement
	 * @param {object} brandsData request data
	 * @return {void} returns nothing
	 */
	_renderElement(brandsData) {
		this.innerHTML = template.render(foreignBrandsFilterTemplate,
			{
				data: brandsData
			}
		);
	}
}

if (window.customElements.get("foreign-brands-filter-element") === undefined) {
	window.customElements.define(
		"foreign-brands-filter-element",
		ForeignBrandsFilterElement
	);
}
