import BasicFilterElement from './basic-filter-element';
import {dom} from 'core-utils';
import {FilterDTO} from "../stockcars-filter-bundle";
import {FORMAT_UTILS, lazyLoad} from '../stockcars-utils-bundle';

export default class GroupFilterElement extends BasicFilterElement {
	constructor() {
		super();
		lazyLoad.register(this);
	}

	/**
	 * default values
	 * @returns {{ATTRIBUTE_FILTER_IDS: string, SELECTOR_FILTER_SELECT_ALL: string, SELECTOR_CARLINE_GROUP_ITEM: string, SELECTOR_MAIN_NAVIGATION: string, SELECTOR_OPEN_CARLINE_GROUP: string, CLASS_ACTIVE: string, CLASS_OPEN: string, CUSTOM_ELEMENT_GROUP_FILTER_ELEMENT: string, CONSTANT_SCROLL_SPEED: number}} returns default values object
	 */
	static get defaults() {
		return {
			ATTRIBUTE_FILTER_IDS: 'data-filter-ids',
			SELECTOR_FILTER_SELECT_ALL: '.sc-filter-select-all',
			SELECTOR_CARLINE_GROUP_ITEM: '.sc-mf-carlinegroup-item',
			SELECTOR_MAIN_NAVIGATION: '.sc-module-main-navigation',
			SELECTOR_OPEN_CARLINE_GROUP: '.sc-mf-carlinegroup.open',
			CLASS_ACTIVE: 'active',
			CLASS_OPEN: 'open',
			CUSTOM_ELEMENT_GROUP_FILTER_ELEMENT: 'group-filter-element',
			CONSTANT_SCROLL_SPEED: 300,
			groupCountClass: '.sc-j-filter-group-count'
		};
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	connectedCallback() {
		super.connectedCallback();
		this._bindContextToFunctions();
		this.addEvents();
		this.update();
	}

	/**
	 * getter for filterIds
	 * @returns {string[]} returns array with filter ids
	 */
	get filterIds() {
		const filterIdsString = this.getAttribute(
			GroupFilterElement.defaults.ATTRIBUTE_FILTER_IDS
		) || '';
		return filterIdsString.split(',');
	}

	/**
	 * _bindContextToFunctions - bind context to necessary functions
	 * @private
	 * @returns {void} returns nothing
	 */
	_bindContextToFunctions() {
		this.modelGroupClickHandler = this.modelGroupClickHandler.bind(this);
		this.modelGroupCheckBoxClickHandler = this.modelGroupCheckBoxClickHandler.bind(
			this
		);
	}

	/**
	 * disconnectedCallback
	 * @returns {void} returns nothing
	 */
	disconnectedCallback() {
		super.disconnectedCallback();
		this.removeEvents();
	}

	/**
	 * addEvents
	 * @returns {void} returns nothing
	 */
	addEvents() {
		this.groupActiveCountLabel = dom.getElement(GroupFilterElement.defaults.SELECTOR_CARLINE_GROUP_ITEM, this);
		this.checkBox = dom.getElement(
			GroupFilterElement.defaults.SELECTOR_FILTER_SELECT_ALL,
			this
		);
		this.tile = dom.getElement(
			GroupFilterElement.defaults.SELECTOR_CARLINE_GROUP_ITEM,
			this
		);
		this.tile.addEventListener('click', this.modelGroupClickHandler);
		this.checkBox.addEventListener(
			'click',
			this.modelGroupCheckBoxClickHandler
		);
	}

	/**
	 * removeEvents
	 * @returns {void} returns nothing
	 */
	removeEvents() {
		this.tile.removeEventListener('click', this.modelGroupClickHandler);
		this.checkBox.removeEventListener(
			'click',
			this.modelGroupCheckBoxClickHandler
		);
	}

	/**
	 * filterObjects get generated filter object for each id
	 * @returns {Array} filterObjects arrays with filter objects
	 */
	get filterObjects() {
		let filterDTOs = [];
		const isGroupActive = this.classList.contains(
			GroupFilterElement.defaults.CLASS_ACTIVE
		);

		this.filterIds.forEach(filterId => {
			const filter = FilterDTO.getClonedFilter(this.filtersMap.get(filterId));
			if (filter) {
				this.setFilterActiveState(isGroupActive, filter);
				filterDTOs.push(filter);
			}
		});

		return filterDTOs;
	}

	setFilterActiveState(isGroupActive, filter) {
		if (!isGroupActive) {
			filter.activateFilter();
		}
		else {
			filter.deactivateFilter();
		}
	}

	/**
	 * update - callback function gets called on model update
	 * @returns {void} returns nothing
	 */
	update() {
		if (this.filtersMap.size) {
			let filtersMap = this.getGroupFilters(this.filterIds);
			this.setGroupActiveState(filtersMap);
			this.setCount(filtersMap);
		}
	}

	/**
	 * setCount sets group count
	 * @param {array<FilterDTO>} filters filters
	 * @returns {void} returns nothing
	 */
	setCount(filters) {
		this.count = this.getCalculatedGroupCount(filters);
	}

	/**
	 * getCalculatedGroupCount - counts all filter counts
	 * @param {Map<FilterDTO>} filtersMap filters
	 * @returns {void} returns nothing
	 */
	getCalculatedGroupCount(filtersMap = new Map()) {
		const count = [...filtersMap.values()].reduce((sum, filter) => sum + filter.count, 0);
		return count;
	}

	_renderCountElement(count_) {
		const countElement = dom.getElement(GroupFilterElement.defaults.groupCountClass, this);
		const countLabel = FORMAT_UTILS.formatCount(count_, BasicFilterElement.defaults.VAGUE_FILTER_VALUE, this._isVagueFilterResults());
		countElement.innerHTML = '(' + countLabel + ')';
	}

	/**
	 * setGroupActiveState - set group filter active when all containing filters active state is true
	 * @param {Map<FilterDTO>} filtersMap filters
	 * @returns {void} returns nothing
	 */
	setGroupActiveState(filtersMap = new Map()) {
		if (filtersMap.size) {
			if (this.isAnyGroupFilterActive(filtersMap)) {
				this.classList.add(GroupFilterElement.defaults.CLASS_ACTIVE);
				this.setGroupCountLabel(filtersMap);
			}
			else {
				this.classList.remove(GroupFilterElement.defaults.CLASS_ACTIVE);
			}
		}
	}

	setGroupCountLabel(filtersMap = new Map()) {
		const activeGroupFilters = [...filtersMap.values()].filter(filter => filter.isActive());
		this.groupActiveCountLabel.setAttribute('data-count', activeGroupFilters.length.toString());
	}

	/**
	 * isAnyGroupFilterActive
	 * @param {array<FilterDTO>} filtersMap filters map
	 * @returns {boolean|*} returns if all filters active state is true
	 */
	isAnyGroupFilterActive(filtersMap = new Map()) {
		return [...filtersMap.values()].some((filterDTO) => filterDTO.isActive() === true);
	}

	/**
	 * getGroupFilters get all containing filters
	 * @param {array} filterIds array of filter Ids
	 * @returns {Map<FilterDTO>} filters group containing filters
	 */
	getGroupFilters(filterIds = []) {
		let filtersMap = new Map();
		const origMap = this.filtersMap;
		filterIds.forEach(filterId => {
			const filterDTO = origMap.get(filterId);
			if (filterDTO) {
				filtersMap.set(filterId, filterDTO);
			}
		});
		return filtersMap;
	}

	/**
	 * isGroupOpen getter to check if group element is open
	 * @returns {boolean} open state
	 */
	get isGroupOpen() {
		return this.classList.contains(GroupFilterElement.defaults.CLASS_OPEN);
	}

	/**
	 * modelGroupCheckBoxClickHandler
	 * @param {event} event click event
	 * @returns {void} returns nothing
	 */
	modelGroupCheckBoxClickHandler(event) {
		event.preventDefault(); // @TODO auf button umstellen

		if (!this.isGroupOpen) {
			this.openSelfAndCloseAllOthers();
		}
	}

	/**
	 * modelGroupClickHandler
	 * @returns {void} returns nothing
	 */
	modelGroupClickHandler() {
		if (this.isGroupOpen) {
			this.closeGroupItem(this);
		}
		else {
			this.openSelfAndCloseAllOthers();
			this.scrollOpenElementToTop();
		}
	}

	/**
	 * openSelfAndCloseAllOthers - opens self and closes all other group tiles
	 * @returns {void} returns nothing
	 */
	openSelfAndCloseAllOthers() {
		const groupItems = dom.getElementsArray(
			GroupFilterElement.defaults.CUSTOM_ELEMENT_GROUP_FILTER_ELEMENT
		);
		groupItems.forEach(item => {
			this.closeGroupItem(item);
		});
		this.classList.add(GroupFilterElement.defaults.CLASS_OPEN);
	}

	/**
	 * closeGroupItem - remove open class from item
	 * @param {HTMLElement} groupItem groupItem
	 * @returns {void} returns nothing
	 */
	closeGroupItem(groupItem) {
		groupItem.classList.remove(GroupFilterElement.defaults.CLASS_OPEN);
	}

	/**
	 * scrollOpenElementToTop - checks if sticky nav is present, calculates offset top and scrolls dom at elements offset top
	 * @returns {void} returns nothing
	 */
	scrollOpenElementToTop() {
		const offset = dom.getOffset(
			GroupFilterElement.defaults.SELECTOR_OPEN_CARLINE_GROUP
		);
		if (offset && offset.top > 0) {
			const stickyNav = dom.getElement(
				GroupFilterElement.defaults.SELECTOR_MAIN_NAVIGATION
			);
			const margin = dom.isElement(stickyNav)
				? stickyNav.clientHeight
				: 0;
			dom.scrollTo(
				offset.top - margin,
				GroupFilterElement.defaults.CONSTANT_SCROLL_SPEED
			);
		}
	}
}
if (window.customElements.get('group-filter-element') === undefined) {
	window.customElements.define('group-filter-element', GroupFilterElement);
}
