import noUISlider from 'nouislider';

import BasicFilterElement from './basic-filter-element';
import {dom} from 'core-utils';
import {scsController} from '../stockcars-bundle';
import {FilterDTO} from '../stockcars-filter-bundle';

export default class RangeFilterElement extends BasicFilterElement {
	constructor() {
		super();
	}


	static get defaults() {
		return {
			tooltipClass: '.noUi-tooltip',
			boldTextClass: 'sc-text-label-2-bold'
		};
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	connectedCallback() {
		super.connectedCallback();
		this._bindContextToFunctions();
		this.initSlider();
		this.update();
	}

	/**
	 * disconnectedCallback
	 * @returns {void} returns nothing
	 */
	disconnectedCallback() {
		super.disconnectedCallback();
	}

	/**
	 * update - callback function for called on model update
	 * @returns {void} returns nothing
	 */
	update() {
		// array with empty strings defaults to min and max (inactive)
		let values = ['', ''];
		if (this.filter) {
			this.state = this.filter.isActive();
			if (this.state && this.filter.values) {
				values = this.filter.values;
			}
			this.setRangeFilterValues(values);
		}
	}

	/**
	 * _bindContextToFunctions - bind 'this' context to necessary functions
	 * @returns {void} returns nothing
	 */
	_bindContextToFunctions() {
		this.onChangeSlider = this.onChangeSlider.bind(this);
		this.onUpdateSlider = this.onUpdateSlider.bind(this);
	}

	/**
	 * rangeValues getter for range values
	 * @returns {Array} range values
	 */
	get rangeValues() {
		let dataValues = dom.getDataAttribute(this, 'range-values');
		return dataValues && dataValues.values ? dataValues.values : [];
	}

	/**
	 * get range step values
	 * @returns {Array} returns range origin values array
	 */
	get originValues() {
		let dataValues = dom.getDataAttribute(this, 'originvalues');
		return dataValues && dataValues.values ? dataValues.values : [];
	}

	/**
	 * initSlider initializes range slider element
	 * @returns {void} returns nothing
	 */
	initSlider() {
		this.createSlider();
		this.initSliderTooltips();
		this.noUiSlider.on(
			'change',
			dom.debounce(this.onChangeSlider, 100, true)
		);
		this.noUiSlider.on('update', this.onUpdateSlider);
	}

	/**
	 * onChangeSlider slider change handler
	 * @param {array} values values from noUi slider
	 * @returns {void} returns nothing
	 */
	onChangeSlider(values) {
		const min = (this.originValues[values[0]] === 'infinity') ? this.originValues[this.originValues.length - 2] : this.originValues[values[0]];
		const max = (values[1] === 0) ? this.originValues[1] : this.originValues[values[1]];
		this.setAttribute('data-current-min-max', `${min},${max}`);

		const filterDTO = new FilterDTO({
			id: this.id,
			active: min > 0 || max !== 'infinity',
			values: [
				min > 0 ? min.toString() : '',
				max !== 'infinity' ? max.toString() : ''
			]
		});
		// trigger scs request/filter update
		scsController.updateFilters({changedFiltersArray:[filterDTO]});
	}

	/**
	 * onUpdateSlider slider update handler
	 * @param {array} values values from noUi slider
	 * @param {number} handle 0 for left handle and 1 for right handle
	 * @returns {void} returns nothing
	 */
	onUpdateSlider(values, handle) {
		let valueLower = '',
			valueUpper = '';

		const dataValues = this.rangeValues;
		const tooltipItems = dom.getElementsArray(RangeFilterElement.defaults.tooltipClass, this);
		const minSliderValue = values[0];
		const maxSliderValue = values[1];
		if (minSliderValue > 0 && minSliderValue < dataValues.length - 1) {
			valueLower = dataValues[values[0]];
		}
		if (maxSliderValue < dataValues.length - 1) {
			valueUpper = dataValues[maxSliderValue];
		}
		this.setTooltipValue(handle, tooltipItems, valueLower, valueUpper);
		this.alignTooltipPosition(handle, tooltipItems);
	}

	get sliderLabelFrom() {
		return window.i18n['sc.filter-range.from.label'];
	}

	get sliderLabelTo() {
		return window.i18n['sc.filter-range.to.label'];
	}

	/**
	 * setTooltipValue
	 * @param {number} handle handle 0 for left handle and 1 for right handle
	 * @param {array} tooltipItems noui tooltip items
	 * @param {string} valueLower value for left handle tooltip
	 * @param {string} valueUpper value for right handle tooltip
	 * @returns {void} returns nothing
	 */
	setTooltipValue(handle, tooltipItems, valueLower, valueUpper) {
		if (handle === 0) {
			tooltipItems[handle].innerHTML =
				`<span>${this.sliderLabelFrom} ${valueLower}</span>`;
		}
		else {
			tooltipItems[handle].innerHTML =
				`<span>${this.sliderLabelTo} ${valueUpper}</span>`;
		}
	}

	/**
	 * alignTooltipPosition shift tooltip that is not cut off in mobile mode
	 * @param {number} handle handle 0 for left handle and 1 for right handle
	 * @param {array} tooltipItems noui tooltip items
	 * @returns {void} returns nothing
	 */
	alignTooltipPosition(handle, tooltipItems) {
		const tooltip = tooltipItems[handle];
		const tooltipWidth = tooltip.offsetWidth;
		const parent = tooltip.parentNode;
		const parentWidth = parent.offsetWidth;
		let handlePercentage, side, handleShift;
		if (handle === 0) {
			handlePercentage = parent.getAttribute('aria-valuenow') * 0.01;
			side = "left";
		}
		else {
			handlePercentage = 1 - (parent.getAttribute('aria-valuenow') * 0.01);
			side = "right";
		}
		handleShift = (tooltipWidth - parentWidth) * handlePercentage * (-1);
		tooltip.style[side] = `${handleShift}px`;
	}

	/**
	 * getMinMaxValuesForRange
	 *
	 * @method getMinMaxValuesForRange
	 * @param {array} rangeValues - rangeObject
	 * @returns {object} returns Object with min and max value from Ranges Values
	 */
	getMinMaxValuesForRange(rangeValues) {
		const minMaxValues = {};
		if (rangeValues) {
			minMaxValues.minValue = !!rangeValues[0] ? rangeValues[0] : -1;
			minMaxValues.maxValue = !!rangeValues[1] ? rangeValues[1] : -1;
		}
		return minMaxValues;
	}

	/**
	 * setRangeFilterValues
	 * @param {array} rangeValues array with min an max value
	 * @returns {void} nothing
	 */
	setRangeFilterValues(rangeValues) {
		const minMaxValues = this.getMinMaxValuesForRange(rangeValues);
		const dataOriginValues = this.originValues;

		const indexMin =
			minMaxValues.minValue > -1
				? dataOriginValues.indexOf(minMaxValues.minValue)
				: 0;

		let indexMax =
			dataOriginValues.length > 0 ? dataOriginValues.length - 1 : 0;

		if (minMaxValues.maxValue > -1) {
			indexMax = dataOriginValues.indexOf(minMaxValues.maxValue);
		}
		if (this.noUiSlider) {
			this.noUiSlider.set([indexMin, indexMax]);
		}
	}

	/**
	 * initSliderTooltips
	 * @returns {void} returns nothing
	 */
	initSliderTooltips() {
		const tooltips = dom.getElementsArray(RangeFilterElement.defaults.tooltipClass, this);
		tooltips.forEach(el => {
			el.classList.add(RangeFilterElement.defaults.boldTextClass);
		});
	}

	/**
	 * setSliderAttributes - sets data range values
	 * @returns {void} returns nothing
	 */
	setSliderAttributes() {
		let dataValues = this.rangeValues;
		dataValues.unshift('0');
		dataValues.push('infinity');
		this.setAttribute('data-range-values', '{"values":' + JSON.stringify(dataValues) + '}');

		let dataOriginValues = this.originValues;
		dataOriginValues.unshift(0);
		dataOriginValues.push('infinity');
		this.setAttribute('data-originvalues', '{"values":' + JSON.stringify(dataOriginValues) + '}');
	}

	/**
	 * createSlider - create noui slider
	 * @returns {void} returns nothing
	 */
	createSlider() {
		this.setSliderAttributes();

		noUISlider.create(this, {
			start: [0, this.rangeValues.length - 1],
			connect: true,
			tooltips: true,
			animate: true,
			behaviour: 'none',
			range: {
				min: 0,
				max: this.rangeValues.length - 1
			},
			format: {
				to: function (value) {
					return parseInt(value, 10);
				},
				from: function (value) {
					return parseInt(value, 10);
				}
			}
		});
	}
}
if (window.customElements.get('range-filter-element') === undefined) {
	window.customElements.define('range-filter-element', RangeFilterElement);
}

