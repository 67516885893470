import {dom} from 'core-utils';
import {STCK_STORE, SELECTORS} from '@oneaudi/stck-store';
import {FORMAT_UTILS} from "../stockcars-utils-bundle";
// import FilterDTO from '../filter-dto';

export default class BasicFilterElement extends HTMLElement {
	constructor() {
		super();
		this._unsubscribeFromFilterModelChangesCallBack = null;
	}


	static get defaults() {
		return {
			countClass: '.sc-j-filter-item-count',
			VAGUE_FILTER_VALUE: SETUPS.get('stockcar.vague.value') ? parseInt(SETUPS.get('stockcar.vague.value'), 10) : 5
		};
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	connectedCallback() {
		this.update = this.update.bind(this);
		this._subscribeToFilterModelChanges();
	}


	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	disconnectedCallback() {
		this._unsubscribeFromFilterModelChanges();
	}

	/**
	 * subscribeToFilterModelChanges
	 * @returns {void} nothing
	 */
	_subscribeToFilterModelChanges() {
		this._unsubscribeFromFilterModelChangesCallBack = STCK_STORE.observeStateFromStore(SELECTORS.FILTERS.getFilterItemsRaw, this.update);
	}

	/**
	 * unsubscribeFromFilterModelChanges
	 * @returns {void} nothing
	 */
	_unsubscribeFromFilterModelChanges() {
		this._unsubscribeFromFilterModelChangesCallBack();
	}


	/**
	 * Basic update function, which has to be overwritten from each filter element module
	 * @returns {void} returns nothing
	 */
	update() {
		throw new Error('update function has to be overwritten');
	}

	/**
	 * state setter for state attribute
	 * @param {boolean} state filter active state
	 * @returns {void} nothing
	 */
	set state(state) {
		this.setAttribute('data-state', state);
	}

	/**
	 * count setter for count attribute
	 * @param {number} count filter count
	 * @returns {void} nothing
	 */
	set count(count) {
		this.setAttribute('data-count', count);
		this._renderCountElement(count);
	}

	_renderCountElement(count_) {
		const countElement = dom.getElement(BasicFilterElement.defaults.countClass, this);
		const countLabel = FORMAT_UTILS.formatCount(count_, BasicFilterElement.defaults.VAGUE_FILTER_VALUE, this._isVagueFilterResults());
		countElement.innerHTML = '(' + countLabel + ')';
	}

	/**
	 * getter for id attribute
	 * @returns {string} returns id
	 */
	get id() {
		return this.getAttribute('data-id') || '';
	}

	/**
	 * getter for state
	 * @returns {boolean} returns active state
	 */
	get state() {
		return this.getAttribute('data-state').toLowerCase() === 'true';
	}

	/**
	 * getter for count
	 * @returns {number} returns count
	 */
	get count() {
		return parseInt(this.getAttribute('data-count'), 10) || 0;
	}

	/**
	 * getter for values
	 * @returns {string} returns values
	 */
	get values() {
		let dataValues = dom.getDataAttribute(this, 'values');
		return dataValues && dataValues.values ? dataValues.values : [];
	}

	/**
	 * getter for filterDTO
	 * @returns {FilterDTO} returns filter
	 */
	get filter() {
		return SELECTORS.FILTERS.getFilterDTOsMap(STCK_STORE.state).get(this.id);
	}

	/**
	 * getter for all filters
	 * @returns {Map<FilterDTO>} returns filtersDTOs
	 */
	get filtersMap() {
		return SELECTORS.FILTERS.getFilterDTOsMap(STCK_STORE.state);
	}

	/**
	 * isVagueFilterResults - checks if scope for vague filter results is set
	 * @return {boolean} - returns if scope is set
	 */
	_isVagueFilterResults() {
		var isVagueScoped = dom.getElement('.sc-scope-vague-filter-results');
		return dom.isElement(isVagueScoped);
	}
}
if (window.customElements.get('basic-filter-element') === undefined) {
	window.customElements.define('basic-filter-element', BasicFilterElement);
}

