import CheckboxFilterElement from './checkbox-filter-element';

export default class CampaignFilterElement extends CheckboxFilterElement {
	constructor() {
		super();
	}

	static get defaults() {
		return {
			classHidden: 'sc-hidden',
			selectorLayerLink: '.nm-layerLink',
			campaignsContainer: '.sc-module-campaign-filter'
		};
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	connectedCallback() {
		super.connectedCallback();
		// Overrides variable 'this.infoIcon' in CheckboxFilterElement
		this.infoIcon = this.querySelector(CampaignFilterElement.defaults.selectorLayerLink);
		this.campaignsContainers = [...document.querySelectorAll(CampaignFilterElement.defaults.campaignsContainer)];
		this.toggleVisibility();
	}

	/**
	 * toggleVisibility
	 * @returns {void} void
	 */
	toggleVisibility() {
		if (this.isCampaignActive(Date.now())) {
			this.classList.remove(CampaignFilterElement.defaults.classHidden);
			this.campaignsContainers.forEach(campaignContainer=>campaignContainer.classList.remove(CampaignFilterElement.defaults.classHidden));
		}
	}

	/**
	 * isCampaignActive
	 * @param {number} now now
	 * @returns {boolean} is active
	 */
	isCampaignActive(now) {
		const {start, end} = this.dataset;
		return !!((start && end) && (Number(start) <= now && Number(end) >= now));
	}
}
if (window.customElements.get('campaign-filter-element') === undefined) {
	window.customElements.define('campaign-filter-element', CampaignFilterElement);
}

