import BasicFilterElement from './basic-filter-element';
import {scsController} from '../stockcars-bundle';
import {lazyLoad} from '../stockcars-utils-bundle';

export default class CheckboxFilterElement extends BasicFilterElement {
	constructor() {
		super();
	}

	static get defaults() {
		return {
			classLayerOpener: '.nm-layerLink',
			selectorInfoIcon: 'sc-filter-item-info-icon-wrap'
		};
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	connectedCallback() {
		super.connectedCallback();
		this._bindContextToFunctions();
		this.infoIcon = this.querySelector(CheckboxFilterElement.defaults.classLayerOpener);
		this.addEvents();
		lazyLoad.register(this, {padding: '500px'});
		this.update();
	}

	/**
	 * removeEvents
	 * @returns {void} returns nothing
	 */
	removeEvents() {
		this.removeEventListener('click', this.clickHandler, true);
	}

	/**
	 * removeEvents
	 * @returns {void} returns nothing
	 */
	addEvents() {
		this.addEventListener('click', this.clickHandler, true);
	}

	/**
	 * _bindContextToFunctions - bind context to necessary functions
	 * @private
	 * @returns {void} returns nothing
	 */
	_bindContextToFunctions() {
		this.clickHandler = this.clickHandler.bind(this);
	}

	/**
	 * disconnectedCallback
	 * @returns {void} returns nothing
	 */
	disconnectedCallback() {
		super.disconnectedCallback();
		this.removeEvents();
	}

	/**
	 * clickHandler checkbox click handler
	 * @param {Event} event click event
	 * @returns {void} returns nothing
	 */
	clickHandler(event) {
		if (event.target !== this.infoIcon && !event.target.classList.contains(CheckboxFilterElement.defaults.selectorInfoIcon)) {
			event.preventDefault();
			this.state = !this.state;
			const filterClone = this.filter;
			if (filterClone) {
				if (this.state) {
					filterClone.activateFilter();
				}
				else {
					filterClone.deactivateFilter();
				}
				scsController.updateFilters({changedFiltersArray:[filterClone]});
			}
		}
	}

	/**
	 * update - callback function gets called on model update
	 * @returns {void} returns nothing
	 */
	update() {
		if (this.filter) {
			this.state = this.filter.isActive();
			this.count = this.filter.count;
		}
	}
}
if (window.customElements.get('checkbox-filter-element') === undefined) {
	window.customElements.define('checkbox-filter-element', CheckboxFilterElement);
}
