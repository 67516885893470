import BasicFilterElement from './basic-filter-element';
import {scsController} from '../stockcars-bundle';
import {FilterDTO} from '../stockcars-filter-bundle';

export default class RangeRadioFilterElement extends BasicFilterElement {
	constructor() {
		super();
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	connectedCallback() {
		super.connectedCallback();
		this._bindContextToFunctions();
		this.radioButtons = this.querySelectorAll('.sc-filter-range-radio-item');
		this.addEvents();
		this.update();
	}

	/**
	 * _bindContextToFunctions - bind 'this' context to necessary functions
	 * @returns {void} returns nothing
	 */
	_bindContextToFunctions() {
		this.clickHandler = this.clickHandler.bind(this);
	}

	/**
	 * addEvents
	 * @returns {void} returns nothing
	 */
	addEvents() {
		this.radioButtons.forEach(radioButton => {
			radioButton.addEventListener('click', this.clickHandler);
		});
	}

	/**
	 * removeEvents
	 * @returns {void} returns nothing
	 */
	removeEvents() {
		this.radioButtons.forEach(radioButton => {
			radioButton.removeEventListener('click', this.clickHandler);
		});
	}

	/**
	 * disconnectedCallback
	 * @returns {void} returns nothing
	 */
	disconnectedCallback() {
		super.disconnectedCallback();
		this.removeEvents();
	}

	/**
	 * update - callback function for called on model update
	 * @returns {void} returns nothing
	 */
	update() {
		if (this.filter) {
			this.setRangeRadioFilterValues();
		}
	}

	/**
	 * modelGroupClickHandler single radio click handler
	 * @param {event} event - click event
	 * @returns {void} returns nothing
	 */
	clickHandler(event) {
		const value = event.currentTarget.getAttribute('data-range-radio-value') || '';

		const filterDTO = new FilterDTO({
			id: this.id,
			active: true,
			values: ['', value]
		});
		scsController.updateFilters({changedFiltersArray:[filterDTO]});
	}

	/**
	 * setRangeRadioFilterValues - sets range radio filter values
	 * @returns {void} returns nothing
	 */
	setRangeRadioFilterValues() {
		if (this.radioButtons && this.filter && this.filter.values && !!this.filter.values[1]) {
			const activeValue = this.filter.values[1];

			this.radioButtons.forEach(radioButton => {
				if (radioButton.getAttribute('data-range-radio-value') === activeValue) {
					radioButton.setAttribute('data-state', 'true');
				}
				else {
					radioButton.setAttribute('data-state', 'false');
				}
			});

			this.setAttribute('data-state', 'true');
		}
		else {
			if (this.radioButtons) {
				this.radioButtons.forEach(radioButton => {
					radioButton.setAttribute('data-state', 'false');
				});
				this.setAttribute('data-state', 'false');
			}
		}
	}
}

if (window.customElements.get('range-radio-filter-element') === undefined) {
	window.customElements.define('range-radio-filter-element', RangeRadioFilterElement);
}
