import CheckboxFilterElement from './checkbox-filter-element';
import {FORMAT_UTILS} from '../stockcars-utils-bundle';

export default class WarrantyFilterElement extends CheckboxFilterElement {
	constructor() {
		super();
	}

	static get defaults() {
		return {
			classWrapper: '.sc-warranty-label-wrap',
			classLabelText: '.sc-warranty-label-text',
			classSkeleton: 'sc-skeleton-line',
			classSkeletonLoad: 'sc-skeleton-load',
			classInfoIconWrap: '.sc-filter-item-info-icon-wrap',
			classHidden: 'sc-hidden'
		};
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	connectedCallback() {
		super.connectedCallback();
		this._addWarrantyLabel();
	}

	_addWarrantyLabel() {
		this.warrantyWrapper = this.querySelector(WarrantyFilterElement.defaults.classWrapper);
		this.warrantyLabelText = this.querySelector(WarrantyFilterElement.defaults.classLabelText);
		this.infoIconWrapper = this.querySelector(WarrantyFilterElement.defaults.classInfoIconWrap);
		let warrantyId = this.dataset.id;
		let warrantyLabel = this.dataset.label || '';
		if (this.warrantyLabelText) {
			this.warrantyLabelText.innerHTML = FORMAT_UTILS.getTextReplace(warrantyId, warrantyLabel);
		}
		if (this.warrantyWrapper) {
			this.warrantyWrapper.classList.remove(WarrantyFilterElement.defaults.classSkeletonLoad);
			this.warrantyWrapper.classList.remove(WarrantyFilterElement.defaults.classSkeleton);
		}
		if (this.infoIconWrapper) {
			this.infoIconWrapper.classList.remove(WarrantyFilterElement.defaults.classHidden);
		}
	}

}
if (window.customElements.get('warranty-filter-element') === undefined) {
	window.customElements.define('warranty-filter-element', WarrantyFilterElement);
}
