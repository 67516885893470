import {scsController} from '../stockcars-bundle';
import BasicFilterElement from "./basic-filter-element";
import {SELECTORS, STCK_STORE} from '@oneaudi/stck-store';

export default class FilterSelectAllElement extends BasicFilterElement {
	constructor() {
		super();
	}

	/**
	 * getter for attribute data-filter-ids
	 * @returns {string} filter-ids string
	 * @private
	 *
	 */
	get _ids() {
		return this.getAttribute('data-filter-ids') || '';
	}

	/**
	 * getter for data-state attribute
	 * @returns {boolean} state
	 * @private
	 */
	get _state() {
		return this.getAttribute('data-state') === 'true';
	}

	/**
	 * getter for filterIds
	 * @returns {string[]} filters ids array
	 * @private
	 */
	get _filterIds() {
		return this._ids.split(',');
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	connectedCallback() {
		super.connectedCallback();
		this._bindContextToFunctions();
		this._addEvents();
		this.update();
	}

	/**
	 * addEvents
	 * @returns {void} returns nothing
	 */
	_addEvents() {
		this.addEventListener('click', this._clickHandler);
	}

	/**
	 * removeEvents
	 * @returns {void} returns nothing
	 */
	_removeEvents() {
		this.removeEventListener('click', this._clickHandler);
	}


	/**
	 * disconnectedCallback
	 * @returns {void} returns nothing
	 */
	disconnectedCallback() {
		super.disconnectedCallback();
		this._removeEvents();
	}

	/**
	 * areAllGroupFiltersActive
	 * @param {Map<FilterDTO>} filtersMap filters
	 * @returns {boolean|*} returns if all filters active state is true
	 */
	areAllGroupFiltersActive(filtersMap = new Map()) {
		return [...filtersMap.values()].every(filterDTO => {
			return filterDTO.isActive() === true;
		});
	}

	/**
	 * getGroupFilters get all containing filters
	 * @returns {Map<FilterDTO>} filters group containing filters
	 */
	getGroupFilters() {
		return SELECTORS.FILTERS.getFilterDTOsByIds(STCK_STORE.state, this._filterIds);
	}

	/**
	 * _getFilterWithNewState
	 * @param {boolean} state current select all state
	 * @returns {Map<FilterDTO>} array of filters to select/deselect with select all checkbox
	 * @private
	 */
	_getFilterWithNewState(state) {
		const groupFilters = new Map(this.getGroupFilters());
		groupFilters.forEach((filterDTO) => {
			if (state) {
				filterDTO.deactivateFilter();
			}
			else {
				filterDTO.activateFilter();
			}
		});
		return groupFilters;
	}

	/**
	 * clickHandler checkbox click handler
	 * @returns {void} returns nothing
	 */
	_clickHandler() {
		const filterDTOs = this._getFilterWithNewState(this._state);
		if (filterDTOs.size) {
			scsController.updateFilters({changedFiltersArray: [...filterDTOs.values()]});
		}
	}

	/**
	 * _bindContextToFunctions - bind context to necessary functions
	 * @private
	 * @returns {void} returns nothing
	 */
	_bindContextToFunctions() {
		this.update = this.update.bind(this);
		this._clickHandler = this._clickHandler.bind(this);
	}

	/**
	 * update
	 * public update method, gets called from observed model
	 * @returns {void} nothing
	 */
	update() {
		const groupFilters = this.getGroupFilters();

		if (groupFilters.size) {
			const allActive = this.areAllGroupFiltersActive(groupFilters);
			if (allActive) {
				this.setAttribute('data-state', 'true');
			}
			else {
				this.setAttribute('data-state', 'false');

			}
		}
	}
}

if (window.customElements.get('filter-select-all-element') === undefined) {
	window.customElements.define('filter-select-all-element', FilterSelectAllElement);
}

