const foreignBrandsFilterTemplate = `
	<h3 class="sc-filter-item-headline sc-hyphenate audi-headline-order-3">{{=window.i18n['sc.foreign-brands-filter-options.headline']||'Fremdmarkenfilter'}}</h3>
	<div class="sc-model-filter-items">
		{{~it.data	:brand}}
			{{? brand && brand.id && brand.members.length}}
				<group-filter-element class="sc-mf-carlinegroup sc-filter-item-group audi-copy-s sc-mf-carlinegroup-bold" data-filter-ids={{~brand.members :model}}{{= model.id}},{{~}}>
					<div class="sc-mf-carlinegroup-item-wrap">
						<div class="sc-mf-carlinegroup-item audi-checkbox" data-group={{= brand.id}} data-count="8" data-state="false">
							<div class="sc-mf-carlinegroup-label">
								<div class="sc-mf-carlinegroup-label-text audi-copy-s">
									<span class="sc-filter-item-label-text">{{= brand.text}}</span>
									<span class="sc-filter-item-label-count sc-mf-carlinegroup-label-count sc-j-filter-group-count"></span>
									<svg class="sc-filter-item-arrow">
										<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/forward-small.svg#audi-icon-forward-small"}}></use>
									</svg>
								</div>
							</div>
						</div>
						<filter-select-all-element class="sc-filter-select-all audi-checkbox" data-state="false" data-group="{{= brand.id}}" data-filter-ids={{~brand.members :model}}{{= model.id}},{{~}}>
							<div class="sc-filter-checkbox-expander">
								<span class="sc-filter-select-all-symbol audi-checkbox-symbol">
									<svg class="nm-icon-select-small nm-icon-select-carline">
										<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/select-small.svg#nm-icon-select-small"}}></use>
									</svg>
								</span>
								<p class="audi-copy-s sc-filter-select-all-label-bold audi-checkbox-label">Alle auswählen</p>
							</div>
						</filter-select-all-element>
					</div>
					<div class="sc-filter-item-list">
						{{~brand.members :model}}
							<checkbox-filter-element class="sc-mf-item sc-filter-item audi-checkbox" data-label={{= model.text}} data-id={{= model.id}} data-group="carline" data-state="false">
								<a x-cq-linkchecker="skip" href="#">
									<div class="sc-mf-item-wrapper">
										<div class="sc-mf-item-label">
											<p class="sc-filter-item-label-text-wrapper audi-copy-s audi-checkbox-label">
												<span class="sc-filter-item-label-text">{{= model.text}}</span>
												<span class="sc-filter-item-label-count sc-j-filter-item-count"></span>
											</p>
											<span class="audi-checkbox-symbol">
												<svg class="nm-icon-select-small nm-icon-select-carline">
													<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/select-small.svg#nm-icon-select-small"}}></use>
												</svg>
											</span>
										</div>
									</div>
								</a>
							</checkbox-filter-element>
						{{~}}
					</div>
				</group-filter-element>
			{{?}}
		{{~}}
	</div>
`;
export {foreignBrandsFilterTemplate};
